import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import headerStyles from './header.module.scss'

const Header = () => {
    const metaDataTitle = useStaticQuery(graphql`
    query MyQuery {
        site {
          siteMetadata {
            title
            twitter
          }
        }
      }
    `)
    return(
        <header>
            <Link className={headerStyles.mainTitle} to='/'>
                { metaDataTitle.site.siteMetadata.title }
            </Link>
            <nav>
                <ul className={headerStyles.navMenu}>
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to='/'>Home</Link>
                    </li>
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to='/notes'>Notes</Link>
                    </li>
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to='/blog'>Writings</Link>
                    </li>
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to='/readingLog'>Reading</Link>
                    </li>

                    { /*
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to='/gallery'>Images</Link>
                    </li>
                    */}
           
                </ul>
            </nav>
        </header>
    )
}

export default Header