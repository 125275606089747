/**
 * This is the general layout file that determines a basic page layout
 * that applies global css styles and adds header & footer components.
 */

import React from 'react'
import Header from './header'
import Footer from './footer'

const Layout = (props) => {
    return(
        <div>
            <Header />
                {props.children}
            <Footer />
        </div>
    )
}

export default Layout