import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import GalleryLayout from '../components/gallery.module.scss'
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

export const query = graphql`
    query($slug: String!){
        contentfulGallery(slug: {eq:$slug}){
            body {
                json
            }
            title
            projectImages{
                id
                file{
                  url
                }
                title
                description
              }
            }
        }
`

const GalleryTemplate = (props) => {
    const post = props.data.contentfulGallery;
    const galleryImages = props.data.contentfulGallery.projectImages;
    const title = props.data.contentfulGallery.title;

    const options = {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => {
            //console.log(node)
            const alt = node.data.target.fields.title['en-US']
            const url = node.data.target.fields.file['en-US'].url
            return (
                <div className={GalleryLayout.textImages}>
                    <img alt={alt} src={url} />
                </div>    
            )
          }
        }
      };

    return(
        <Layout>
            <h1> { title }</h1>
            <div className={GalleryLayout.text}>
                { documentToReactComponents(post.body.json, options ) }
            </div>
            <div className={GalleryLayout.imageGrid}> 
            {
                galleryImages.map(( { file, description }) => {
                    const imgURL = file.url;
                    const imgID = file.id;
                    const theTitle = description;
                    console.log(theTitle)
                    return( 
                    <div className={GalleryLayout.card} key={imgID}>     
                        <img src= { imgURL } width={400}></img>
                        <div className={GalleryLayout.container}>
                            <p>{ theTitle }</p>
                        </div> 
                    </div>    
                    )
                })


            }

            </div>  
        </Layout>
    )
}


export default GalleryTemplate  