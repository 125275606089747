/**
 * Footer
 */

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import footerStyles from './footer.module.scss'


const Footer = () => {
  const metaData = useStaticQuery(graphql`
  query myQuery {
      site {
        siteMetadata {
          author
          twitter
        }
      }
    }
  `)
  return (
    
    <footer className={footerStyles.footer}>
      <p> 
        <a href="https://twitter.com/DanDither">N-1%DroneMedia Bot </a> | 
        <a href="https://twitter.com/motiondesign_01">{ metaData.site.siteMetadata.twitter }</a>
      </p>
      
      
    </footer>
  )
}

export default Footer
